
    import {
        defineComponent, PropType,
    } from 'vue';

    import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';

    import usePartialPaymentPopupRef from '@ve/components/partialPayment/common/use/usePartialPaymentPopupRef';
    import usePartialPaymentBannerForProductCard from '@ve/components/partialPayment/common/use/usePartialPaymentBannerForProductCard';

    export default defineComponent({
        name: 'PartialPaymentBannerForProductCard',
        props: {
            countryName: {
                type: String,
                default: '',
            },
            products: {
                type: Array as PropType<PartialPaymentTourProduct[]>,
                default: () => ([]),
            },
            productType: {
                type: String as PropType<'hotelOffer' | 'tourOffer'>,
                default: 'tourOffer',
            },
        },
        setup(props, ctx) {
            const {
                minPriceText,
                minPercentText,
                productTypeLabelText,
            } = usePartialPaymentBannerForProductCard(props);

            const {
                openPopup,
                partialPaymentPopupRef,
            } = usePartialPaymentPopupRef(props, ctx);

            return {
                minPriceText,
                minPercentText,
                openPopup,
                partialPaymentPopupRef,
                productTypeLabelText,
            };
        },
    });
