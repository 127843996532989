
    import {
        defineComponent,
    } from 'vue';

    export default defineComponent({
        props: {
            isActive: {
                type: Boolean,
                default: false,
            },
        },
    });
