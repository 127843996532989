import {
    computed,
    unref,
    SetupContext,
    toRefs, ref,
    getCurrentInstance,
} from 'vue';
import { useElementSize, useWindowSize } from '@vueuse/core';
import { onClickOutside } from '@ve/composables/vueuse/onClickOutside';
import useVueProxy from '@ve/services/useVueProxy';

import type DialogWrapperDesktop from '@ve/components/dialogWrapper/desktop/DialogWrapperDesktop.vue';
import { IDialogWrapperProps, IUseDialogWrapperMethods, IContentWrapperStyles } from '../types';

function useComponentId() {
    return getCurrentInstance()?.proxy._uid;
}

const useDialogWrapper = (props: IDialogWrapperProps, ctx: SetupContext):IUseDialogWrapperMethods => {
    const {
        preventClose, fullscreen, width, preventCloseWithCloseButton,
    } = toRefs(props);
    const { emit } = ctx;
    const { $root } = useVueProxy();
    const $vfm = $root?.$vfm;
    const uid = useComponentId();

    const isOpened = ref(false);

    const refVueFinalModal = ref(null);
    const refVueFinalModalContent = ref(null);

    const { height: windowHeight, width: windowWidth } = useWindowSize();
    const { height: dialogContentHeight, width: dialogContentWidth } = useElementSize(refVueFinalModalContent);

    const contentBiggerWindowWidth = computed(() => unref(dialogContentWidth) > unref(windowWidth));
    const contentBiggerWindowHeight = computed(() => unref(dialogContentHeight) > unref(windowHeight));

    const contentWrapperStyles = computed(() => {
        const alignSelf = unref(contentBiggerWindowWidth) ? 'self-start' : 'center';
        let styles = {
            'max-width': unref(width),
            'align-self': alignSelf,
        } as IContentWrapperStyles;
        if (unref(fullscreen)) {
            const height = unref(contentBiggerWindowHeight) ? 'fit-content' : '100vh';
            styles = {
                'max-width': unref(width),
                'align-self': alignSelf,
                'min-height': height,
                height,
            };
        }
        return styles;
    });

    const open = () => {
        isOpened.value = true;
    };
    const close = () => {
        isOpened.value = false;
    };

    const clickCloseBtn = () => {
        emit('close-button-click');

        if (unref(preventClose) && unref(preventCloseWithCloseButton)) return;

        close();
    };

    const closeOthers = () => {
        if (!$vfm) {
            return;
        }

        for (let i = 0; i < $vfm.openedModals.length; i += 1) {
            const modal = $vfm.openedModals[i];
            const dialogWrapper = modal?.$parent as InstanceType<typeof DialogWrapperDesktop>;
            if (dialogWrapper?._uid !== uid && dialogWrapper?.$props?.canBeClosedByOthers) modal.$emit('should-be-closed');
        }
    };

    const onOpened = () => {
        emit('show');
        closeOthers();
    };

    const onClosed = () => {
        emit('close');
    };

    onClickOutside(refVueFinalModalContent, () => {
        if (!unref(preventClose)) close();
    });

    return {
        open,
        close,
        onOpened,
        onClosed,
        isOpened,
        refVueFinalModal,
        refVueFinalModalContent,
        contentWrapperStyles,
        dialogContentHeight,
        contentBiggerWindowWidth,
        contentBiggerWindowHeight,
        clickCloseBtn,
    };
};

export default useDialogWrapper;
