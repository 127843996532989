
    import { defineComponent, PropType } from 'vue';
    import { wordEnding } from '@ve/services/textModifiers';
    import { IOperatorDataForPartialPayment } from '@ve/components/partialPayment/partialPaymentPopup/common/types/OperatorDataForPartialPayment';
    import usePartialPaymentPopupOperatorsList from '@ve/components/partialPayment/partialPaymentPopup/common/use/usePartialPaymentPopupOperatorsList';

    export default defineComponent({
        name: 'PartialPaymentPopupOperatorsList',
        props: {
            titleText: {
                type: String,
                default: '',
            },
            operatorsData: {
                type: Array as PropType<IOperatorDataForPartialPayment[]>,
                default: () => ([]),
            },
            productType: {
                type: String as PropType<'hotelOffer' | 'tourOffer'>,
                default: 'tourOffer',
            },
        },
        setup(props) {
            const {
                afterMinDaysAvailableBeforeCheckInDateText,
                afterDaysAfterReservationForFullPaymentText,
                afterDaysBeforeCheckInDateForFullPaymentText,
                afterMinDaysAvailableBeforeFreeCancellationDateText,
                freeCancellationEndText,
            } = usePartialPaymentPopupOperatorsList(props);

            return {
                wordEnding,
                afterMinDaysAvailableBeforeCheckInDateText,
                afterDaysAfterReservationForFullPaymentText,
                afterDaysBeforeCheckInDateForFullPaymentText,
                afterMinDaysAvailableBeforeFreeCancellationDateText,
                freeCancellationEndText,
            };
        },
    });
