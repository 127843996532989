
    import {
        defineComponent, PropType, toRefs, unref,
    } from 'vue';
    import {
        IReturnProductTour, IReturnProductHotelOffer, IReturnProductRailways, IReturnProductType,
    } from '@ve/pages/hotel/types';
    import useProducts from '@ve/pages/hotel/use/useProducts';
    import HotelButton from './HotelButton.vue';
    import TourButton from './TourButton.vue';
    import RailwayButton from './RailwayButton.vue';

    export default defineComponent({
        components: {
            HotelButton,
            TourButton,
            RailwayButton,
        },
        props: {
            product: {
                type: Object as PropType<IReturnProductType>,
                required: true,
            },
            products: {
                type: Array as PropType<(IReturnProductTour | IReturnProductHotelOffer | IReturnProductRailways)[]>,
                required: true,
            },
        },
        setup(props, { emit }) {
            const { products } = toRefs(props);
            const {
                hotelOffers,
                railwayTours,
                tours,
            } = useProducts(products);
            const hotelHandler = () => {
                emit('hotel-click', unref(hotelOffers));
                emit('product-click');
            };
            const railwayHandler = () => {
                emit('railway-click', unref(railwayTours));
                emit('product-click');
            };
            return {
                hotelHandler,
                railwayHandler,
                hotelOffers,
                railwayTours,
                tours,
            };
        },
    });
