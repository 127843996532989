import { startOfDay, differenceInDays, parse } from 'date-fns';
import { Leg, CountryCondition } from '@ve/types';
import { CountryId } from '@entities/country';
import { OperatorId, Operator } from '@entities/operator';

function isOperatorTranslateWrongNightsInHotel(operator: Operator | null, country: CountryCondition | null): boolean {
    return operator?.id === OperatorId.Paks
        || operator?.id === OperatorId.NTK
        || (operator?.id === OperatorId.BiblioGlobus && CountryId.Maldives === country?.id);
}
function isOperatorTranslateWrongNights(operator: Operator | null, country: CountryCondition | null): boolean {
    return operator?.id === OperatorId.Paks
        || operator?.id === OperatorId.NTK
        || operator?.id === OperatorId.AleanTour
        || operator?.id === OperatorId.TezTour
        || (operator?.id === OperatorId.BiblioGlobus && CountryId.Maldives === country?.id);
}

function calculateNightsByDates(thereDate: string, backDate: string): number {
    const startDateThere = startOfDay(parse(thereDate, 'yyyy-MM-dd HH:mm:ss', new Date()));
    const startDateBack = startOfDay(parse(backDate, 'yyyy-MM-dd HH:mm:ss', new Date()));
    const amountNightsByDates = differenceInDays(startDateBack, startDateThere);
    return amountNightsByDates;
}

function calculateNightsByRouteTime(thereLegs: Leg[], backLegs: Leg[], nights: number): number {
    if (!thereLegs?.length || !backLegs?.length) return nights;

    const thereDepartureDate = thereLegs[0].departureDate;
    const backDepartureDate = backLegs[0].departureDate;
    return calculateNightsByDates(thereDepartureDate, backDepartureDate);
}

function calculateNightsInHotelByRouteTime(thereLegs: Leg[], backLegs: Leg[], nightsInHotel: number): number {
    if (!thereLegs?.length || !backLegs?.length) return nightsInHotel;

    const thereArrivalDate = thereLegs[thereLegs.length - 1].arrivalDate;
    const backDepartureDate = backLegs[0].departureDate;
    return calculateNightsByDates(thereArrivalDate, backDepartureDate);
}

export {
    calculateNightsInHotelByRouteTime,
    calculateNightsByRouteTime,
    isOperatorTranslateWrongNightsInHotel,
    isOperatorTranslateWrongNights,
};