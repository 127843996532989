

    import {
        defineComponent, ref, computed, unref, onMounted,
    } from 'vue';
    import ButtonV from '@ve/components/button/desktop/Button.vue';
    import { useMutations } from 'vuex-composition-helpers';
    import { NAMESPACE as HOTEL_PAGE_GALLERY_STORE } from '@ve/pages/hotel/store/HotelPageGalleryStore';
    import { IHotelPageGalleryStoreMutations } from '@ve/pages/hotel/store/types';

    export default defineComponent({
        components: {
            ButtonV,
        },
        props: {
            src: {
                type: String,
                required: true,
            },
            imagePreview: {
                type: String,
                default: '',
            },
        },
        setup() {
            const {
                setIsVideoOnPage, setIsWatchedVideo, setIsWatchVideoUntilTheEnd, setIsWaitingForVideoLoading,
            } = useMutations<IHotelPageGalleryStoreMutations>(HOTEL_PAGE_GALLERY_STORE, ['setIsVideoOnPage', 'setIsWatchedVideo', 'setIsWatchVideoUntilTheEnd', 'setIsWaitingForVideoLoading']);

            const videoPlayerRef = ref<HTMLVideoElement | null>(null);
            const isUserWasPlayVideo = ref(false);

            const canPlay = ref(false);

            const isPlayButtonVisible = computed(() => !unref(isUserWasPlayVideo));

            const playVideo = () => {
                if (!unref(canPlay)) setIsWaitingForVideoLoading(true);
                setIsWatchedVideo(true);
                isUserWasPlayVideo.value = true;
                videoPlayerRef.value?.play();
            };

            onMounted(() => setIsVideoOnPage(true));

            return {
                videoPlayerRef,
                setIsWatchVideoUntilTheEnd,
                playVideo,
                canPlay,
                isUserWasPlayVideo,
                isPlayButtonVisible,
            };
        },
    });
