import { FirstPaymentDefinition } from '@ve/types';

export default function (firstPaymentDefinitions: (FirstPaymentDefinition | null)[]): string {
    let textPrepayments = '';
    const percentPrepayments: number[] = firstPaymentDefinitions.reduce((acc, firstPaymentDefinition) => {
        const percentPrepayment = firstPaymentDefinition?.firstPaymentPart ? firstPaymentDefinition?.firstPaymentPart * 100 : 0;
        if (percentPrepayment && acc.indexOf(percentPrepayment) === -1) {
            acc.push(percentPrepayment);
        }
        return acc;
    }, [100] as number[]);

    if (percentPrepayments.length) {
        textPrepayments = `${percentPrepayments.sort((a, b) => a - b).join(', ')}%`;
    }

    return textPrepayments;
}