
    import { defineComponent, PropType, unref } from 'vue';
    import CommonButton from '@ve/components/button/desktop/Button.vue';
    import formatNumber from '@/js/common/Domain/Service/mixins/formatNumber';
    import TourUrlAdapter from '@ve/pages/hotel/adapters/TourUrlAdapter';
    import { IReturnProductTour } from '@ve/pages/hotel/types';
    import useProduct from '@ve/pages/hotel/use/useProduct';
    import NightDifference from '@fr/vue/components/nightDiffrence/NightDifference.vue';
    import { useState } from 'vuex-composition-helpers';
    import { MapForFiltersState } from '@ve/components/hotel/filters/common/store/types';
    import ProductPrepayment from './components/ProductPrepayment.vue';

    export default defineComponent({
        components: {
            CommonButton,
            ProductPrepayment,
            NightDifference,
        },
        methods: {
            formatNumber,
        },
        props: {
            tourProducts: {
                type: Array as PropType<IReturnProductTour[]>,
                required: true,
            },
        },
        setup(props) {
            const { directFlight } = useState<MapForFiltersState>('mapForFilters', ['directFlight']);
            const { tourProducts } = props;
            const goToTourPage = () => {
                const { url } = TourUrlAdapter(tourProducts, unref(directFlight));
                window.location.href = url;
            };
            return {
                goToTourPage,
                ...useProduct<IReturnProductTour>(tourProducts),
            };
        },
    });
