import { IHotelPageGalleryStore } from './types';

export const NAMESPACE = 'hotelPageGalleryStore';

export default {
    namespaced: true,
    state: {
        isVideoOnPage: false,
        isWatchedVideo: false,
        isWatchVideoUntilTheEnd: false,
        isWaitingForVideoLoading: false,
        isGalleryOpenedInPopup: false,
        isWatchGalleryUntilTheEnd: false,
        isClickedOnThumb: false,
        watchPhotos: [],
    },
    mutations: {
        setIsVideoOnPage: (state, isVideoOnPage) => {
            state.isVideoOnPage = isVideoOnPage;
        },
        setIsWatchedVideo: (state, isWatchedVideo) => {
            state.isWatchedVideo = isWatchedVideo;
        },
        setIsWatchVideoUntilTheEnd: (state, isWatchVideoUntilTheEnd) => {
            state.isWatchVideoUntilTheEnd = isWatchVideoUntilTheEnd;
        },
        setIsWaitingForVideoLoading: (state, isWaitingForVideoLoading) => {
            state.isWaitingForVideoLoading = isWaitingForVideoLoading;
        },
        setIsGalleryOpenedInPopup: (state, isGalleryOpenedInPopup) => {
            state.isGalleryOpenedInPopup = isGalleryOpenedInPopup;
        },
        setIsWatchGalleryUntilTheEnd: (state, isWatchGalleryUntilTheEnd) => {
            state.isWatchGalleryUntilTheEnd = isWatchGalleryUntilTheEnd;
        },
        addIndexOfWatchPhoto: (state, photo) => {
            state.watchPhotos.push(photo);
        },
        setIsClickedOnThumb: (state, isClickedOnThumb) => {
            state.isClickedOnThumb = isClickedOnThumb;
        },
    },
    getters: {
        amountOfUniqueWatchedPhotos: (state) => new Set(state.watchPhotos).size,
        paramsForHotelPageGalleryAnalytic: (state, { amountOfUniqueWatchedPhotos }) => ({
            isVideoOnPage: state.isVideoOnPage,
            isWatchedVideo: state.isWatchedVideo,
            isWatchVideoUntilTheEnd: state.isWatchVideoUntilTheEnd,
            isWaitingForVideoLoading: state.isWaitingForVideoLoading,
            isGalleryOpenedInPopup: state.isGalleryOpenedInPopup,
            isWatchGalleryUntilTheEnd: state.isWatchGalleryUntilTheEnd,
            isClickedOnThumb: state.isClickedOnThumb,
            amountOfUniqueWatchedPhotos,
        }),
    },
} as IHotelPageGalleryStore;