import { HotelCategoryForFilter } from '@ve/components/filters/hotelCategoriesFilter/common/types';

export default [
    {
        id: 7,
        stars: 5,
        label: '5 звёзд',
    },
    {
        id: 4,
        stars: 4,
        label: '4 звезды',
    },
    {
        id: 3,
        stars: 3,
        label: '3 звезды',
    },
    {
        id: 2,
        stars: 2,
        label: '2 звезды',
    },
    {
        id: 1,
        stars: 1,
        label: '1 звезда',
    },
    {
        id: 10,
        stars: 0,
        label: 'Хостелы',
    },
    {
        id: 5,
        stars: 0,
        label: 'Апартаменты',
    },
    {
        id: 16,
        stars: 0,
        label: 'Санатории',
    },
    {
        id: 17,
        stars: 0,
        label: 'Пансионаты',
    },
    {
        id: 19,
        stars: 0,
        label: 'Базы отдыха',
    },
] as HotelCategoryForFilter[];