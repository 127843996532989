
    import {
        defineComponent, PropType, toRefs, computed, unref,
    } from 'vue';
    import { IconBase } from '@shared/ui';
    import { asyncSvgLoader } from '@shared/lib';
    import viewIcon from './img/view.svg';
    import mealIcon from './img/meal.svg';
    import operatorIcon from './img/operator.svg';
    import calendarIcon from './img/calendar.svg';
    import profileIcon from './img/profile.svg';
    import excludeIcon from './img/exclude.svg';
    import tariffIcon from './img/tariff.svg';

    type TIcon = 'meal' | 'operator' | 'view' | 'profile' | 'calendar' | 'exclude' | 'tariff';

    export default defineComponent({
        components: {
            IconBase,
        },
        props: {
            iconName: {
                type: String as PropType<TIcon>,
                required: true,
            },
        },
        setup(props) {
            const { iconName } = props;
            const componentIcon: Record<TIcon, any> = {
                meal: asyncSvgLoader(mealIcon),
                operator: asyncSvgLoader(operatorIcon),
                view: asyncSvgLoader(viewIcon),
                calendar: asyncSvgLoader(calendarIcon),
                profile: asyncSvgLoader(profileIcon),
                exclude: asyncSvgLoader(excludeIcon),
                tariff: asyncSvgLoader(tariffIcon),
            };
            return {
                componentIcon: componentIcon[iconName],
            };
        },
    });
