import {
    IReturnProductType, IReturnProductHotelOffer, IReturnProductTour, IReturnProductRailways,
} from '@ve/pages/hotel/types';
import {
 computed, ComputedRef, Ref, unref,
} from 'vue';

interface IReturnProductsUse {
    hotelOffers: ComputedRef<IReturnProductHotelOffer[]>,
    railwayTours: ComputedRef<IReturnProductRailways[]>,
    tours: ComputedRef<IReturnProductTour[]>,
}

export default (arrayProducts: Ref<IReturnProductType[]> | ComputedRef<IReturnProductType[]>): IReturnProductsUse => {
    const hotelOffers = computed(() => unref(arrayProducts).filter((product) => product.type === 'HotelOfferProduct')) as ComputedRef<IReturnProductHotelOffer[]>;

    const railwayTours = computed(() => unref(arrayProducts).filter((product) => product.type === 'RailwayTourProduct')) as ComputedRef<IReturnProductRailways[]>;

    const tours = computed(() => unref(arrayProducts).filter((product) => product.type === 'TourProduct')) as ComputedRef<IReturnProductTour[]>;

    return {
        hotelOffers,
        railwayTours,
        tours,
    };
};