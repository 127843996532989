var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-slider",class:{
        'image-slider_has-vertical-thumbs': _vm.isThumbsVerticalDirection,
        'image-slider_image-border-radius': _vm.borderRadius,
        'image-slider_big-thumbs': _vm.isBigThumbs,
    }},[_c('swiper',{staticClass:"image-slider__slides",class:{'image-slider__slides_without-thumbs': !_vm.hasThumbs},attrs:{"update-on-window-resize":"","threshold":5,"resizeObserver":_vm.isResizeObserver,"initial-slide":_vm.selectedIndex,"space-between":6,"pagination":_vm.paginationRender,"navigation":_vm.navigation ? {
            nextEl: '.image-slider__navigation-button_next',
            prevEl: '.image-slider__navigation-button_prev',
            disabledClass: 'image-slider__navigation-button_disabled'
        } : false,"preload-images":false,"lazy":{
            loadPrevNext: _vm.isShouldPreLoadNextOrPrevSlide,
        },"thumbs":{
            swiper: _vm.hasThumbs ? _vm.thumbsSwiper : null,
            slideThumbActiveClass: 'image-slider__thumb-slide_active',
        }},on:{"swiper":_vm.setImagesSwiper,"activeIndexChange":_vm.indexChanged,"reachEnd":function($event){return _vm.$emit('is-end')}}},[(_vm.navigation)?_c('div',{staticClass:"image-slider__navigation"},_vm._l((['next', 'prev']),function(direction){return _c('div',{key:direction,staticClass:"image-slider__navigation-button",class:[{
                    'image-slider__navigation-button_small': _vm.navigationSize === 'small',
                    'image-slider__navigation-button_large': _vm.navigationSize === 'large',
                }, `image-slider__navigation-button_${direction}`]})}),0):_vm._e(),_vm._v(" "),(_vm.contents.length)?_vm._l((_vm.contents),function(content,index){return _c('swiper-slide',{key:`${content.src}_${index}`},[(content.type === 'video')?_c('video-content',{staticClass:"swiper-lazy",attrs:{"src":content.src,"imagePreview":content.preview}}):_c('img',{staticClass:"image-slider__image swiper-lazy",attrs:{"src":_vm.emptySrc,"data-src":content.src,"data-srcset":content.srcset || '',"sizes":content.sizes || '',"alt":content.alt},on:{"click":function($event){return _vm.$emit('big-image-click')}}}),_vm._v(" "),_c('div',{staticClass:"swiper-lazy-preloader"})],1)}):_vm._e(),_vm._v(" "),(_vm.isShouldShowPhotosCounter)?_c('div',{staticClass:"image-slider__counter"},[_vm._v(_vm._s(_vm.selectedIndex+1)+"/"+_vm._s(_vm.contents.length))]):_vm._e()],2),_vm._v(" "),(_vm.hasThumbs)?_c('swiper',{staticClass:"image-slider__thumbs",attrs:{"update-on-window-resize":"","threshold":5,"space-between":6,"lazy":{
            loadPrevNext: true,
        },"scrollbar":{
            hide: false,
            draggable: true,
        },"preload-images":false,"slides-per-view":_vm.countOfThumbs,"direction":"horizontal","breakpoints":_vm.breakpoints,"watch-slides-visibility":true,"watch-slides-progress":true},on:{"swiper":_vm.setThumbsSwiper,"click":function($event){return _vm.$emit('thumb-click')},"changeDirection":_vm.detectThumbsDirection}},[(_vm.contentThumbs.length)?_vm._l((_vm.contentThumbs),function(image,index){return _c('swiper-slide',{key:`${image.src}_${index}`,staticClass:"image-slider__thumb-slide"},[_c('img',{staticClass:"image-slider__image swiper-lazy",attrs:{"data-src":image.src,"src":_vm.emptySrc,"alt":image.alt}}),_vm._v(" "),(image.type === 'video-icon')?_c('video-icon',{attrs:{"isActive":index === _vm.selectedIndex}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"swiper-lazy-preloader"})],1)}):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }