/* eslint-disable camelcase */
declare const window: {
    _tmr: any
};

interface ITopMailParams {
    type: string
    id: number
    value: number,
    goal: string,
    params: {
        product_id: number
    },
}

const TOP_MAIL_ID = 3255764;

export default class MailTrackingService {
    protected sendAnalytics(params: ITopMailParams): void {
        if (!window._tmr) {
            return;
        }

        window._tmr.push(params);

        console.log(`track_${params.goal}_price:${params.value}_hotelId:${params.params.product_id}`);
    }

    protected getParams(price: number, hotelId: number, pageType: string): ITopMailParams {
        const goal = this.getGoalType(pageType);

        return {
            type: 'reachGoal',
            id: TOP_MAIL_ID,
            value: price,
            goal,
            params: { product_id: hotelId },
        };
    }

    protected getGoalType(page: string): string {
        switch (page) {
            case 'hotelPage': {
                return 'viewProduct';
            }

            case 'tourPage': {
                return 'add2cart';
            }

            default: {
                return '';
            }
        }
    }
}
