import { computed, Ref } from 'vue';

import { IOperatorDataForPartialPayment } from '@ve/components/partialPayment/partialPaymentPopup/common/types/OperatorDataForPartialPayment';

interface IProps {
    titleText: string;
    operatorsData: IOperatorDataForPartialPayment[];
    productType: 'hotelOffer' | 'tourOffer';
}

interface IReturn {
    afterMinDaysAvailableBeforeCheckInDateText: Ref<string>;
    afterDaysAfterReservationForFullPaymentText: Ref<string>;
    afterDaysBeforeCheckInDateForFullPaymentText: Ref<string>;
    afterMinDaysAvailableBeforeFreeCancellationDateText: Ref<string>;
    freeCancellationEndText: Ref<string>;
}

const usePartialPaymentPopupOperatorsList = (props: IProps):IReturn => {
    const { productType } = props;

    const afterMinDaysAvailableBeforeCheckInDateText = computed(() => {
        if (productType === 'hotelOffer') return ' до заселения нужно внести остаток';
        return ' до вылета нужно внести остаток';
    });

    const afterDaysAfterReservationForFullPaymentText = computed(() => {
        if (productType === 'hotelOffer') return ' после подтверждения бронирования.';
        return ' после подтверждения тура.';
    });

    const afterDaysBeforeCheckInDateForFullPaymentText = computed(() => {
        if (productType === 'hotelOffer') return ' до заселения.';
        return ' до вылета.';
    });

    const afterMinDaysAvailableBeforeFreeCancellationDateText = computed(() => ' до окончания периода с бесплатной отменой нужно внести остаток');
    const freeCancellationEndText = computed(() => (' до окончания этого периода.'));
    return {
        afterMinDaysAvailableBeforeCheckInDateText,
        afterDaysAfterReservationForFullPaymentText,
        afterDaysBeforeCheckInDateForFullPaymentText,
        afterMinDaysAvailableBeforeFreeCancellationDateText,
        freeCancellationEndText,
    };
};

export default usePartialPaymentPopupOperatorsList;
