import {
 Ref, unref, computed, ComputedRef,
} from 'vue';
import { IReturnProductType } from '@ve/pages/hotel/types';

export default function (product: Ref<IReturnProductType | null> | ComputedRef<IReturnProductType | null>) {
    const productTextByType = computed(() => {
        if (unref(product)?.type === 'TourProduct') {
            return 'Выбрать тур с авиа';
        }
        if (unref(product)?.type === 'HotelOfferProduct') {
            return 'Выбрать номер';
        }
        return 'Выбрать тур с Ж/Д';
    });
    return {
        productTextByType,
    };
}