
    import '@ve/components/dialogWrapper/common/activateVueFinalModal';

    import {
        computed,
        defineComponent,
        SetupContext, toRefs, unref,
    } from 'vue';

    // eslint-disable-next-line import/no-cycle
    import useDialogWrapper from '@ve/components/dialogWrapper/common/use/useDialogWrapper';

    export default defineComponent({
        name: 'DialogWrapperDesktop',
        props: {
            dialogClass: {
                type: String,
                default: '',
            },
            overlayClass: {
                type: String,
                default: '',
            },
            hideOverlay: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: '1000px',
            },
            preventClose: {
                type: Boolean,
                default: false,
            },
            closeOnEscape: {
                type: Boolean,
                default: true,
            },
            showCloseButton: {
                type: Boolean,
                default: true,
            },
            preventCloseWithCloseButton: {
                type: Boolean,
                default: false,
            },
            fullscreen: {
                type: Boolean,
                default: false,
            },
            canBeClosedByOthers: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['show', 'close'],
        setup(props, ctx: SetupContext) {
            const { dialogClass, overlayClass } = toRefs(props);

            const {
                open,
                close,
                onOpened,
                onClosed,
                isOpened,
                refVueFinalModal,
                refVueFinalModalContent,
                contentWrapperStyles,
                contentBiggerWindowHeight,
                clickCloseBtn,
            } = useDialogWrapper(props, ctx);

            const fullOverlayClass = computed(() => {
                const defaultClass = 'dialog-wrapper__modal-overlay';
                if (unref(overlayClass)) return `${unref(overlayClass)} ${defaultClass}`;
                return defaultClass;
            });

            const fullDialogClass = computed(() => {
                const defaultClass = 'dialog-wrapper__dialog-content';
                if (unref(dialogClass)) return `${unref(dialogClass)} ${defaultClass}`;
                return defaultClass;
            });

            return {
                open,
                close,
                onOpened,
                onClosed,
                clickCloseBtn,
                isOpened,
                refVueFinalModal,
                refVueFinalModalContent,
                contentWrapperStyles,
                contentBiggerWindowHeight,
                fullOverlayClass,
                fullDialogClass,
            };
        },
    });
