import {
 Ref, ref, computed, unref,
} from 'vue';
import { IReturnProductType, IReturnProductTour } from '../types';
import useProductText from './useProductText';

export default <T extends IReturnProductType>(arrayProducts: Ref<T[]> | T[]) => {
    const firstProduct = computed(() => unref(arrayProducts)[0] || null);

    const getFirstProducts = computed(() => {
        const takenProducts: IReturnProductType['type'][] = [];
        return unref(arrayProducts)
            .reduce((acc, product) => {
                if (takenProducts.indexOf(product.type) === -1) {
                    acc.push(product);
                    takenProducts.push(product.type);
                }
                return acc;
            }, [] as IReturnProductType[])
            .sort((_firstProduct, _secondProduct) => {
                const productsPriority: Record<IReturnProductType['type'], number> = {
                    TourProduct: 1,
                    RailwayTourProduct: 2,
                    HotelOfferProduct: 3,
                };
                const priorityFirst = productsPriority[_firstProduct.type];
                const prioritySecond = productsPriority[_secondProduct.type];

                return priorityFirst - prioritySecond;
            });
    });

    const { productTextByType } = useProductText(firstProduct);

    return {
        productTextByType,
        getFirstProducts,
        firstProduct,
    };
};