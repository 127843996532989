
    // Vue 2 only supports by Swiper 6 with swiper-vue2
    // Swiper 6 docs https://swiper6.vercel.app/swiper-api#styles
    // Swiper 6 demos https://swiper6.vercel.app/demos#default

    import {
        defineComponent, ref, unref, PropType, Ref, toRefs, computed, watch, onMounted, onUnmounted, nextTick,
    } from 'vue';

    // Import Swiper Vue.js components
    import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
    import { ImagesSliderPhoto, ImagesSliderVideo } from '@ve/components/images-slider/common/types';

    //import Swiper core and required modules
    import {
        Swiper as ISwiper,
        Navigation, Thumbs, Lazy, SwiperOptions, Scrollbar, Pagination,
    } from 'swiper/core';
    import VideoContent from './components/VideoContent.vue';
    import VideoIcon from './components/VideoIcon.vue';
    import useContentSlider from './use/useContentSlider';

    // Import Swiper styles
    // use require because of bug with import https://github.com/nolimits4web/swiper/issues/3777
    require('swiper/components/pagination/pagination.scss');
    require('swiper/swiper.scss');
    require('swiper/components/lazy/lazy.scss');

    // install Swiper modules
    SwiperCore.use([Lazy, Navigation, Thumbs, Scrollbar, Pagination]);

    export default defineComponent({
        components: {
            Swiper,
            VideoContent,
            VideoIcon,
            SwiperSlide,
        },
        //emits index выбранного в данный момент слайда (для синхронизации с родителем)
        emits: ['slide-changed', 'big-image-click'],
        props: {
            //src и alt картинок в формате ImagesSliderPhoto[]
            images: {
                type: Array as PropType<ImagesSliderPhoto[]>,
                required: true,
                default: [],
            },
            //selectedIndex номер слайда, который нужно выбрать (реактивный, для выбора слайда извне)
            selectedIndex: {
                type: Number,
                default: 0,
            },

            //показать кнопки навигации
            navigation: {
                type: Boolean,
                default: true,
            },

            //"large" или "small" размер стрелок навигации
            navigationSize: {
                type: String,
                default: 'small',
            },
            video: {
                type: Object as PropType<ImagesSliderVideo | null>,
                default: null,
            },

            // превьюшки слайдов в формате ImagesSliderPhoto[]. Если не передано - превьюшки не показываются
            thumbs: {
                type: Array as PropType<ImagesSliderPhoto[]>,
                default: () => [],
            },

            //breakpoints для управления слайдами-превьюшками в формате https://swiperjs.com/swiper-api#param-breakpoints
            breakpoints: {
                type: Object as PropType<SwiperOptions['breakpoints']>,
                default: () => {
                },
            },

            borderRadius: {
                type: Boolean,
                default: false,
            },

            isBigThumbs: {
                type: Boolean,
                default: false,
            },

            countOfThumbs: {
                type: Number,
                default: 10,
            },

            isPagination: {
                type: Boolean,
                default: false,
            },

            isDynamicPagination: {
                type: Boolean,
                default: false,
            },

            isResizeObserver: {
                type: Boolean,
                default: false,
            },

            isShouldPreLoadNextOrPrevSlide: {
                type: Boolean,
                default: true,
            },

            isShouldShowPhotosCounter: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, { emit }) {
            const {
                thumbs,
                images,
                isPagination,
                isDynamicPagination,
                isResizeObserver,
                video,
                selectedIndex,
            } = toRefs(props);

            const { contents, contentThumbs } = useContentSlider(video, images, thumbs);

            const imagesSwiper = ref(null) as Ref<null | ISwiper>;
            const thumbsSwiper = ref(null) as Ref<null | ISwiper>;

            const hasThumbs = computed(() => !!unref(thumbs).length);

            const setImagesSwiper = (swiper: ISwiper) => {
                imagesSwiper.value = swiper;
            };

            const setThumbsSwiper = (swiper: ISwiper) => {
                thumbsSwiper.value = swiper;
            };

            const isThumbsVerticalDirection = ref(false);
            const detectThumbsDirection = (swiper: ISwiper) => {
                isThumbsVerticalDirection.value = swiper.params.direction === 'vertical';
            };

            watch(thumbsSwiper, (thumbsSwiperValue) => {
                if (thumbsSwiperValue) {
                    detectThumbsDirection(thumbsSwiperValue);
                }
            });

            const updateSwipers = () => {
                const imagesSwiperInstance = unref(imagesSwiper);
                const imagesThumbsInstance = unref(thumbsSwiper);
                nextTick(() => {
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                        unref(imagesSwiperInstance)?.update();
                        unref(imagesThumbsInstance)?.update();
                    }, 1);
                });
            };

            watch([images, thumbs], () => {
                updateSwipers();
            }, { immediate: true });

            watch(selectedIndex, (value) => {
                const imagesSwiperInstance = unref(imagesSwiper);
                if (imagesSwiperInstance) {
                    imagesSwiperInstance.slideTo(value);
                }
            });

            const indexChanged = () => {
                const imagesSwiperInstance = unref(imagesSwiper);
                if (imagesSwiperInstance) {
                    emit('slide-changed', imagesSwiperInstance.activeIndex);
                }
            };

            const staticPaginationRender = {
                clickable: true,
                renderBullet: (_index: number, className: string) => `<span class="${className}"></span>`,
            };

            const dynamicPaginationRender = {
                dynamicBullets: true,
                dynamicMainBullets: 3,
            };

            const paginationRender = computed(() => {
                if (unref(isPagination)) return staticPaginationRender;
                if (unref(isDynamicPagination)) return dynamicPaginationRender;
                return false;
            });

            const emptySrc = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
            return {
                emptySrc,
                thumbsSwiper,
                hasThumbs,
                setImagesSwiper,
                setThumbsSwiper,
                detectThumbsDirection,
                isThumbsVerticalDirection,
                indexChanged,
                paginationRender,
                contents,
                contentThumbs,
            };
        },
    });
