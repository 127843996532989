import { TouristGroup } from '@ve/types';
import wordEnding from '@/js/common/Domain/Service/mixins/wordEnding';

export default (touristGroup: TouristGroup | null | undefined, genetive = false): string => {
    if (!touristGroup) return '';
    const adultsTextEndings = !genetive ? 'ый;ых;ых' : 'ого;ых;ых';
    const infantsTextEndings = !genetive ? 'ец;ца;цев' : 'ца;ца;ев';
    const kidsTextEndings = !genetive ? 'ребенок;детей;детей' : 'ребенка;детей;детей';
    const kidsAgesTextEndings = 'год;года;лет';

    const {
        adults = 0,
        kids = 0,
        infants = 0,
        kidsAges = [],
    } = touristGroup;
    let touristsText = `${adults} взросл${wordEnding(adults || 0, adultsTextEndings)}`;

    if (infants && kids) {
        touristsText += ', ';
    } else if (infants || kids) {
        touristsText += ' и ';
    }

    if (infants) {
        touristsText += `${infants} младен${wordEnding(infants || 0, infantsTextEndings)}`;
        if (kids) {
            touristsText += ' и ';
        }
    }
    if (kids) {
        touristsText += `${kids} ${wordEnding(kids || 0, kidsTextEndings)} `;
        touristsText += `(${kidsAges.join(', ')} ${wordEnding(kidsAges[kidsAges.length - 1] || 0, kidsAgesTextEndings)})`;
    }

    return touristsText;
};
