import {
    computed, Ref, unref, ComputedRef,
} from 'vue';
import { ImagesSliderVideo, ImagesSliderPhoto } from '../types';

type ReturnContentSlider = {
    contents: ComputedRef<(ImagesSliderVideo | ImagesSliderPhoto)[]>;
    contentThumbs: ComputedRef<(ImagesSliderVideo | ImagesSliderPhoto)[]>;
}

export default function (_video: Ref<ImagesSliderVideo | null>, images: Ref<ImagesSliderPhoto[]>, thumbs: Ref<ImagesSliderPhoto[]>): ReturnContentSlider {
    const contents = computed<(ImagesSliderVideo | ImagesSliderPhoto)[]>(() => {
        const video = unref(_video);
        if (!video || !unref(images).length) return unref(images);
        const result: (ImagesSliderVideo | ImagesSliderPhoto)[] = [];
        const firstImage = unref(images)[0];
        result[0] = firstImage;
        result[1] = {
            ...video,
            preview: firstImage.src,
        };
        const imagesWithoutFirstImage = unref(images).slice(1);
        return [...result, ...imagesWithoutFirstImage];
    });

    const contentThumbs = computed<(ImagesSliderVideo | ImagesSliderPhoto)[]>(() => {
        const video = unref(_video);
        if (!unref(video) || !unref(thumbs).length) return unref(thumbs);
        const result: (ImagesSliderVideo | ImagesSliderPhoto)[] = [];
        const firstThumb = unref(thumbs)[0];
        result[0] = firstThumb;
        result[1] = {
            ...firstThumb,
            type: 'video-icon',
        };
        const thumbsWithoutFirstThumb = unref(thumbs).slice(1);
        return [...result, ...thumbsWithoutFirstThumb];
    });

    return {
        contents,
        contentThumbs,
    };
}