import type { Fn } from '@vueuse/shared';
import { ref } from 'vue';
import { MaybeElementRef, unrefElement, useEventListener } from '@vueuse/core';

interface ConfigurableWindow {
    /*
     * Specify a custom `window` instance, e.g. working with iframes or in testing environments.
     */
    window?: Window
}

const defaultWindow = typeof window !== 'undefined' ? window : undefined;

export interface OnClickOutsideOptions extends ConfigurableWindow {
    /**
     * List of elements that should not trigger the event.
     */
    ignore?: MaybeElementRef[]
    /**
     * Use capturing phase for internal event listener.
     * @default true
     */
    capture?: boolean
    /**
     * Run handler function if focus moves to an iframe.
     * @default false
     */
    detectIframe?: boolean
}

export type OnClickOutsideEvents = Pick<WindowEventMap, 'click' | 'mousedown' | 'mouseup' | 'touchstart' | 'touchend' | 'pointerdown' | 'pointerup'>

/**
 * Listen for clicks outside of an element.
 *
 * @see https://vueuse.org/onClickOutside
 * @param target
 * @param handler
 * @param options
 */
export function onClickOutside<T extends OnClickOutsideOptions, E extends keyof OnClickOutsideEvents = 'pointerdown'>(
    target: MaybeElementRef,
    handler: (evt: T['detectIframe'] extends true ? PointerEvent | FocusEvent : PointerEvent) => void,
    options: T = {} as T,
) {
    const {
 window = defaultWindow, ignore, capture = true, detectIframe = false,
} = options;

    if (!window) return;

    const shouldListen = ref(true);

    let fallback: number;

    function eventPath(event: PointerEvent) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const path = ((event.composedPath && event.composedPath()) || event.path) as HTMLElement[] | undefined;

        if (path != null) return path;

        function getParents(node: HTMLElement, memo: HTMLElement[] = []): HTMLElement[] {
            const parentNode = node.parentNode as HTMLElement | null;

            return parentNode
                ? getParents(parentNode, memo.concat([parentNode]))
                : memo;
        }

        return [event.target].concat(getParents(event.target as HTMLElement));
    }

    const listener = (event: PointerEvent) => {
        window.clearTimeout(fallback);

        const el = unrefElement(target);
        const composedPath = eventPath(event);

        if (!el || el === event.target || composedPath.indexOf(el) >= 0 || !shouldListen.value) return;

        if (ignore && ignore.length > 0) {
            // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
            if (ignore.some((target) => {
                const elTarget = unrefElement(target);
                return elTarget && (event.target === elTarget || composedPath.indexOf(elTarget) >= 0);
            })) return;
        }

        handler(event);
    };

    const cleanup = [
        useEventListener(window, 'click', listener, { passive: true, capture }),
        useEventListener(window, 'pointerdown', (e) => {
            const el = unrefElement(target);
            const path = eventPath(e) as EventTarget[];
            shouldListen.value = !!el && !(path.indexOf(el) >= 0);
        }, { passive: true }),
        useEventListener(window, 'pointerup', (e) => {
            if (e.button === 0) {
                const path = eventPath(e) as EventTarget[];
                e.composedPath = () => path;
                fallback = window.setTimeout(() => listener(e), 50);
            }
        }, { passive: true }),
        detectIframe && useEventListener(window, 'blur', (event) => {
            const el = unrefElement(target);
            if (
                document.activeElement?.tagName === 'IFRAME'
                && !el?.contains(document.activeElement)
            ) handler(event as any);
        }),
    ].filter(Boolean) as Fn[];

    const stop = () => cleanup.forEach((fn) => fn());

    // eslint-disable-next-line consistent-return
    return stop;
}