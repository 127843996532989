
    import {
        defineComponent, PropType,
    } from 'vue';
    import { TWarningBannerColor } from '.';

    export default defineComponent({
        props: {
            small: {
                type: Boolean,
                default: false,
            },
            mobile: {
                type: Boolean,
                default: false,
            },
            typeColor: {
                type: String as PropType<TWarningBannerColor>,
                default: 'red',
            },
            borderRadius: {
                type: Boolean,
                default: false,
            },
        },
    });
