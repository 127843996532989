
    import {
        defineComponent, PropType,
    } from 'vue';

    export default defineComponent({
        name: 'hotel-offers-tab',
        props: {
            isActive: {
                type: Boolean as PropType<boolean>,
            },
        },
    });
