import _ from 'underscore';
import firstPaymentDefinitionService from '@/js/common/Domain/Service/firstPaymentDefinition';
import getTourProductFirstPaymentDefinitions from '@/js/common/Domain/Service/hotelSearch/services/getTourProductFirstPaymentDefinitions';
import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';
import { FirstPaymentDefinitionsOfOperatorDataForPartialPayment } from '@ve/components/partialPayment/partialPaymentPopup/common/types/FirstPaymentDefinitionOfOperatorDataForPartialPayment';

//todo - Переменовать название. prepareFirstPaymentDefinitionForAnyProduct -> geUniqPaymentDefinitionsByProductsWithOperatorCover

const prepareFirstPaymentDefinitionForAnyProduct = (products: PartialPaymentTourProduct[]):FirstPaymentDefinitionsOfOperatorDataForPartialPayment[] => {
    const allFirstPaymentDefinitions = firstPaymentDefinitionService.getData();

    const productFirstPaymentDefinitions = getTourProductFirstPaymentDefinitions(products);

    if (productFirstPaymentDefinitions.length === 0) return [];

    return _.reduce(productFirstPaymentDefinitions, (acc:FirstPaymentDefinitionsOfOperatorDataForPartialPayment[], item) => {
        const foundedOperator = allFirstPaymentDefinitions.find((definition) => definition.operator === item.operator);

        if (foundedOperator?.operatorCover) {
            acc.push({ ...item, operatorCover: foundedOperator.operatorCover });
        }

        return acc;
    }, []);
};

export default prepareFirstPaymentDefinitionForAnyProduct;
