export type TpriceJump = 'increase' | 'decrease' | 'none'

interface IgetPriceJump {
    priceJumpType: TpriceJump
    priceJumpValue: number
}

interface IgetTrackJumpPrice {
    shouldTrackPriceJump: boolean
    priceJumpType: TpriceJump
    priceJumpValue: number
    goalParams: Record<string, any>,
}

export default class PriceJumpTracking {
    protected getPriceJump(priceFrom: number, priceTo: number): IgetPriceJump {
        const priceJump = priceTo - priceFrom;
        const noPriceJump = priceJump === 0
            ? 'none'
            : null;

        const priceJumpType = priceJump > 0
            ? 'increase'
            : 'decrease';

        return {
            priceJumpType: noPriceJump || priceJumpType,
            priceJumpValue: Math.abs(priceJump),
        };
    }

    trackJumpPrice(priceFrom: number, priceTo: number, jumpPlace: string): IgetTrackJumpPrice {
        const {
            priceJumpType,
            priceJumpValue,
        } = this.getPriceJump(priceFrom, priceTo);

        const isTrackingPriceJumpHighier500 = priceJumpValue >= 500 && priceJumpValue < 5000;
        const isTrackingPriceJumpHighier5000 = priceJumpValue >= 5000;
        const getGoalParams = (jumpType: TpriceJump) => ({
            price_change: {
                price_change_cnt: 1,
                price_change_type: {
                    [`${jumpPlace}_${jumpType}_cnt`]: 1,
                    [`${jumpPlace}_${jumpType}_amount`]: priceJumpValue,
                },
            },
        });
        const getPriceJumpValue = () => {
            if (isTrackingPriceJumpHighier500) {
                return 500;
            }
            if (isTrackingPriceJumpHighier5000) {
                return 5000;
            }

            return 0;
        };

        return {
            shouldTrackPriceJump: isTrackingPriceJumpHighier500 || isTrackingPriceJumpHighier5000,
            priceJumpType,
            priceJumpValue: getPriceJumpValue(),
            goalParams: getGoalParams(priceJumpType),
        };
    }
}