export const phone = {
    tokens: {
        '#': { pattern: /[0-9]/ },
        '*': { pattern: /[0-6,9]/ },
    },
    mask: '8 (*##) ###-##-##',
};

export default {
    phone,
};