
    import {
        defineComponent, PropType, computed, toRefs, unref,
    } from 'vue';
    import countPrepayments from '@ve/services/entities-services/prepayments/countPrepayments';
    import { IReturnProductType } from '@ve/pages/hotel/types';
    import TextIcon from './TextIcon.vue';

    export default defineComponent({
        components: {
            TextIcon,
        },
        props: {
            products: {
                type: Array as PropType<IReturnProductType[]>,
                required: true,
            },
            price: {
                type: Number,
                default: 0,
            },
            visibleInstalment: {
                type: Boolean,
                default: true,
            },
        },
        setup(props) {
            const { products, price } = (props);
            const firstPaymentDefinitions = computed(() => products.reduce((acc, product) => {
                const _firstPaymentDefinitions = product.firstPaymentDefinitions;
                if (_firstPaymentDefinitions) {
                    acc.push(..._firstPaymentDefinitions);
                }
                return acc;
            }, [] as IReturnProductType['firstPaymentDefinitions']));
            const percentPrepayments = computed(() => countPrepayments(unref(firstPaymentDefinitions)));
            const installmentPlanText = computed(() => (price < 250000 ? 'и рассрочка' : ''));

            return {
                firstPaymentDefinitions,
                percentPrepayments,
                installmentPlanText,
            };
        },
    });
