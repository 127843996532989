var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"warning-banner"},[_c('div',{staticClass:"warning-banner__container",class:{
            'warning-banner__container_small-sized': _vm.small,
            'warning-banner__container_for-mobile': _vm.mobile,
            'warning-banner__container_gray': _vm.typeColor === 'gray',
            'warning-banner__container_yellow': _vm.typeColor === 'yellow',
            'warning-banner__container_border-radius': _vm.borderRadius,
        }},[_c('i',{staticClass:"warning-banner__icon",class:{
                'warning-banner__icon_small-sized': _vm.small,
                'warning-banner__icon_red': _vm.typeColor === 'red',
                'warning-banner__icon_gray': _vm.typeColor === 'gray',
                'warning-banner__icon_yellow': _vm.typeColor === 'yellow',
            }}),_vm._v(" "),_c('div',{staticClass:"warning-banner__text"},[_c('div',{staticClass:"warning-banner__main-text",class:{'warning-banner__main-text_for-mobile': _vm.mobile}},[_vm._t("main-text")],2),_vm._v(" "),(_vm.$slots.description)?_c('div',{staticClass:"warning-banner__description",class:{
                    'warning-banner__description_for-mobile': _vm.mobile,
                }},[_vm._t("description")],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }