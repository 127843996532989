import { ref, Ref, SetupContext } from 'vue';
import {
    IPartialPaymentPopup,
} from '@ve/components/partialPayment/partialPaymentPopup/common/types/IPartialPaymentPopup';
import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';

interface IProps {
    countryName: string;
    products: PartialPaymentTourProduct[];
    productType: 'hotelOffer' | 'tourOffer' | 'railwayTour' | 'all';
}
interface IReturn {
    openPopup(arg: SetupContext):void;
    partialPaymentPopupRef: Ref<IPartialPaymentPopup | null>;
}

const usePartialPaymentPopupRef = (props: IProps, ctx: SetupContext):IReturn => {
    const partialPaymentPopupRef: Ref<IPartialPaymentPopup | null> = ref(null);

    const openPopup = (): void => {
        const params = {
            products: props.products,
            countryName: props.countryName,
            productType: props.productType,
            showTourOfferProductsInPopup: props.productType !== 'hotelOffer',
            showHotelOfferProductsInPopup: props.productType !== 'tourOffer',
        };
        ctx.emit('open-popup', params);
    };

    return {
        openPopup,
        partialPaymentPopupRef,
    };
};

export default usePartialPaymentPopupRef;