import { computed, Ref, unref } from 'vue';

import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';
import { PartialPaymentProductType } from '@ve/components/partialPayment/common/types/PartialPaymentProductType';

interface IProps {
    products: Ref<PartialPaymentTourProduct[]>;
    hotelPageProduct: Ref<boolean>;
    productType: Ref<PartialPaymentProductType>;
}

interface IReturn {
    onlyTourOfferProducts: Ref<PartialPaymentTourProduct[]>;
    onlyHotelOfferProducts: Ref<PartialPaymentTourProduct[]>;
}

const useProductsByType = ({ products, hotelPageProduct, productType }:IProps):IReturn => {
    const onlyTourOfferProducts = computed(() => {
        if (unref(hotelPageProduct)) {
            return unref(products).filter((product) => product.type === 'TourProduct');
        }
        if (unref(productType) === 'tourOffer') {
            return unref(products);
        }
        return [];
    });

    const onlyHotelOfferProducts = computed(() => {
        if (unref(hotelPageProduct)) {
            return unref(products).filter((product) => product.type === 'HotelOfferProduct')?.map((product) => product.hotelOffer?.toJSON());
        }
        if (unref(productType) === 'hotelOffer') {
            return unref(products);
        }
        return [];
    });

    return {
        onlyTourOfferProducts,
        onlyHotelOfferProducts,
    };
};

export default useProductsByType;