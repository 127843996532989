
    import {
        defineComponent, PropType,
    } from 'vue';
    import CommonButton from '@fr/vue/components/button/desktop/Button.vue';
    import formatNumber from '@/js/common/Domain/Service/mixins/formatNumber';
    import { IReturnProductHotelOffer } from '@ve/pages/hotel/types';
    import useProduct from '@ve/pages/hotel/use/useProduct';
    import NightDifference from '@fr/vue/components/nightDiffrence/NightDifference.vue';
    import ProductPrepayment from './components/ProductPrepayment.vue';

    export default defineComponent({
        components: {
            CommonButton,
            ProductPrepayment,
            NightDifference,
        },
        methods: {
            formatNumber,
        },
        props: {
            hotelOfferProducts: {
                type: Array as PropType<IReturnProductHotelOffer[]>,
                default: [],
            },
        },
        setup(props, { emit }) {
            const { hotelOfferProducts } = props;
            const open = () => {
                emit('click');
            };

            return {
                open,
                ...useProduct<IReturnProductHotelOffer>(hotelOfferProducts),
            };
        },
    });
