import {
    computed, Ref, toRefs, unref,
} from 'vue';

import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';

interface IProps {
    countryName: string;
    products: PartialPaymentTourProduct[];
    productType: 'hotelOffer' | 'tourOffer';
}

interface IReturn {
    productTypeLabelText: Ref<string>;
}

const useCashbackPartialPaymentBannerForProductCard = (props:IProps):IReturn => {
    const { productType } = toRefs(props);

    const productTypeLabelText = computed(() => (unref(productType) === 'hotelOffer' ? 'отеля' : 'тура'));

    return {
        productTypeLabelText,
    };
};

export default useCashbackPartialPaymentBannerForProductCard;
