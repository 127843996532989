
    import {
        defineComponent, PropType, computed, unref, toRefs,
    } from 'vue';
    import { IconBase } from '@shared/ui';
    import { asyncSvgLoader, wordEnding } from '@shared/lib';
    import {
        Leg, CountryCondition,
    } from '@ve/types';
    import { Operator } from '@entities/operator';
    import {
        isOperatorTranslateWrongNights,
        isOperatorTranslateWrongNightsInHotel,
        calculateNightsByRouteTime,
        calculateNightsInHotelByRouteTime,
    } from './services/OperatorsNights';

    import iconNights from './img/nights.svg';

    export default defineComponent({
        components: {
            IconBase,
            iconNights: asyncSvgLoader(iconNights),
        },
        methods: {
            wordEnding,
        },
        props: {
            nights: {
                type: Number,
                default: 0,
            },
            nightsInHotel: {
                type: Number,
                default: 0,
            },
            country: {
                type: Object as PropType<CountryCondition | null>,
                default: null,
            },
            operator: {
                type: Object as PropType<Operator | null>,
                default: null,
            },
            thereLegs: {
                type: Array as PropType<Leg[]>,
                default: () => [],
            },
            backLegs: {
                type: Array as PropType<Leg[] >,
                default: () => [],
            },
            smallText: {
                type: Boolean,
                default: false,
            },
            isHotelOffer: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const {
                nights, nightsInHotel, operator, isHotelOffer, country, thereLegs, backLegs,
            } = toRefs(props);

            const calculatedNightsInHotel = computed(() => {
                const isWrongNightsInHotelByOperator = isOperatorTranslateWrongNightsInHotel(unref(operator), unref(country));
                return isWrongNightsInHotelByOperator ? calculateNightsInHotelByRouteTime(unref(thereLegs), unref(backLegs), unref(nightsInHotel)) : unref(nightsInHotel);
            });
            const calculatedNightsInTour = computed(() => {
                const isWrongNightsTourByOperator = isOperatorTranslateWrongNights(unref(operator), unref(country));
                return isWrongNightsTourByOperator ? calculateNightsByRouteTime(unref(thereLegs), unref(backLegs), unref(nights)) : unref(nights);
            });
            const isDifferenceNightByNightsInHotel = computed(() => unref(calculatedNightsInTour) !== unref(calculatedNightsInHotel));

            const isDifferenceNightsOperatorByCalculatedNights = computed(() => unref(nights) !== unref(calculatedNightsInTour));

            const nightsInHotelText = computed(() => (unref(isHotelOffer) ? `на ${unref(nights)} ноч${wordEnding(unref(nights), 'ь;и;ей')} в отеле` : `из них ${unref(calculatedNightsInHotel)} в отеле`));

            return {
                isDifferenceNightByNightsInHotel,
                isDifferenceNightsOperatorByCalculatedNights,
                nightsInHotelText,
                calculatedNightsInHotel,
                calculatedNightsInTour,
            };
        },
    });
