
    import {
        computed,
        defineComponent, PropType, unref, watch,
    } from 'vue';

    import { wordEnding } from '@ve/services/textModifiers';
    import usePartialPaymentPopup from '@ve/components/partialPayment/partialPaymentPopup/common/use/usePartialPaymentPopup';
    import DialogWrapperDesktop from '@ve/components/dialogWrapper/desktop/DialogWrapperDesktop.vue';
    import PartialPaymentPopupOperatorsList from '@ve/components/partialPayment/partialPaymentPopup/desktop/PartialPaymentPopupOperatorsList.vue';
    import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';
    import { PartialPaymentProductType } from '@ve/components/partialPayment/common/types/PartialPaymentProductType';

    export default defineComponent({
        name: 'PartialPaymentPopup',
        components: { PartialPaymentPopupOperatorsList, DialogWrapperDesktop },
        props: {
            countryName: {
                type: String,
                default: '',
            },
            products: {
                type: Array as PropType<PartialPaymentTourProduct[]>,
                default: () => ([]),
            },
            showTourOfferProductsInPopup: {
                type: Boolean,
                default: true,
            },
            showHotelOfferProductsInPopup: {
                type: Boolean,
                default: true,
            },
            hotelPageProduct: {
                type: Boolean,
                default: false,
            },
            productType: {
                type: String as PropType<PartialPaymentProductType>,
                default: 'tourOffer',
            },
            isPopupVisible: {
                type: Object as PropType<boolean>,
                default: false,
            },
        },
        setup(props) {
            const {
                partialPaymentPopup,
                tourOperatorsForPartialPayment,
                hotelOperatorsForPartialPayment,
                isPopupVisible,
                openPopup,
            } = usePartialPaymentPopup(props);

            const isPopupOpened = computed(() => unref(isPopupVisible));
            watch(isPopupOpened, (value) => {
                if (value) openPopup();
            });

            return {
                partialPaymentPopup,
                tourOperatorsForPartialPayment,
                hotelOperatorsForPartialPayment,
                openPopup,
                wordEnding,
            };
        },
    });
