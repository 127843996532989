import {
    computed, Ref, toRefs, unref,
} from 'vue';
import { formatNumber } from '@ve/services/textModifiers';
import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';

interface IProps {
    countryName: string;
    products: PartialPaymentTourProduct[];
    productType: 'hotelOffer' | 'tourOffer';
}

interface IReturn {
    minPriceText: Ref<string>;
    minPercentText: Ref<string>;
    productTypeLabelText: Ref<string>;
}

const usePartialPaymentBannerForProductCard = (props:IProps):IReturn => {
    const { products, productType } = toRefs(props);

    const firstProduct = computed(() => (unref(products).length > 0 ? unref(products)[0] : null));

    const minPercent:Ref<number> = computed(() => unref(firstProduct)?.firstPaymentDefinition?.firstPaymentPart || 0);

    const minPrice = computed(() => {
        const firstProductValue = unref(firstProduct);
        if (firstProductValue) {
            if (unref(productType) === 'hotelOffer') {
                return formatNumber(firstProductValue.price * unref(minPercent));
            }
            const priceWithOilTax = unref(firstProduct)?.tour?.get('priceWithOilTax') || 0;
            return formatNumber(priceWithOilTax * unref(minPercent));
        }
        return 0;
    });

    const minPriceText = computed(() => (`${unref(minPrice)} руб.`));

    const minPercentText = computed(() => (`${Math.round(unref(minPercent) * 100)}%`));

    const productTypeLabelText = computed(() => (unref(productType) === 'hotelOffer' ? 'отель' : 'тур'));

    return {
        minPriceText,
        minPercentText,
        productTypeLabelText,
    };
};

export default usePartialPaymentBannerForProductCard;