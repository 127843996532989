
    import { format } from 'date-fns';
    import { ru } from 'date-fns/locale';
    import {
        defineComponent, PropType, Ref, ref, unref, computed, ComputedRef,
    } from 'vue';
    import wordEnding from '@/js/common/Domain/Service/mixins/wordEnding';

    import DialogWrapperDesktop from '@ve/components/dialogWrapper/desktop/DialogWrapperDesktop.vue';
    import ImagesSlider from '@ve/components/images-slider/common/ImagesSlider.vue';
    import { ImagesSliderPhoto } from '@ve/components/images-slider/common/types';
    import imageProxy from '@/js/common/Domain/Service/imageProxy';
    import touristGroupText from '@ve/services/entities-services/tourists/touristGroupText';
    import FactoryButtonsHotel from '@ve/pages/hotel/components/hotelButtons/FactoryButtonsHotel.vue';
    import useProduct from '@ve/pages/hotel/use/useProduct';
    import {
        IReturnProductHotelOffer, IReturnProductRailways, IReturnProductTour,
    } from '@ve/pages/hotel/types';

    export default defineComponent({
        name: 'room-description-dialog-desktop',
        components: {
            DialogWrapperDesktop,
            FactoryButtonsHotel,
            ImagesSlider,
        },
        props: {
            dialogData: {
                type: Object as PropType<{ products: Array<IReturnProductHotelOffer & IReturnProductRailways & IReturnProductTour> }>,
                required: true,
            },
        },
        setup(props) {
            const { dialogData } = props;
            const popupWrapperRef: Ref<InstanceType<typeof DialogWrapperDesktop> | null> = ref(null);

            const products = computed(() => dialogData?.products || []);

            const { firstProduct, getFirstProducts } = useProduct(products);

            const open = () => {
                const popupWrapperRefValue = unref(popupWrapperRef);
                if (popupWrapperRefValue) {
                    popupWrapperRefValue.open();
                }
            };

            const close = () => {
                const popupWrapperRefValue = unref(popupWrapperRef);
                if (popupWrapperRefValue) {
                    popupWrapperRefValue.close();
                }
            };

            const getDateText = (date: Date) => `${format(date, 'dd MMMM, EEEEEE', { locale: ru })}`; // пример -> 09 марта, чт
            const getDateStartText = computed(() => (unref(firstProduct)?.checkInDateRange ? getDateText(unref(firstProduct)?.checkInDateRange.from) : null));
            const getDateEndText = computed(() => (unref(firstProduct)?.checkInDateRange ? getDateText(unref(firstProduct)?.checkInDateRange.to) : null));

            // @ts-ignore: Unreachable code error
            const images: ComputedRef<ImagesSliderPhoto[]> = computed(() => unref(firstProduct)?.room.photos?.map((photo) => ({
                src: imageProxy(photo, {
                    width: 320,
                    height: 240,
                }),
                alt: 'hotel-photo',
            })) || []);

            // @ts-ignore: Unreachable code error
            const thumbs: ComputedRef<ImagesSliderPhoto[]> = computed(() => unref(firstProduct)?.room.photos?.map((photo) => ({
                src: imageProxy(photo, {
                    width: 92,
                    height: 69,
                }),
                alt: 'hotel-photo',
            })) || []);

            return {
                popupWrapperRef,
                open,
                close,
                images,
                thumbs,
                getDateStartText,
                getDateEndText,
                products,
                firstProduct,
                getFirstProducts,
                touristGroupText,
                wordEnding,
            };
        },
    });
