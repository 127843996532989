/* eslint-disable */
import { chain } from 'underscore';
import { IReturnProductTour } from '@ve/pages/hotel/types';
// @ts-ignore: Unreachable code error
import searchSessionService from '@/js/common/Domain/Service/search/searchSession';

export default (tourProducts: IReturnProductTour[], directFlightFilter: boolean | undefined): { url: string } => {
    if (!tourProducts.length) return { url: '' };
    const paramsForRouter = {
        sid: searchSessionService.getSearchId(),
        hsid: searchSessionService.getHotelSearchId(),
    };

    const isExclusive = tourProducts[0]?.isExclusive;
    const hotelId = tourProducts[0].hotel;
    if (isExclusive) {
        // @ts-ignore: Unreachable code error
        paramsForRouter.isExclusive = true;
    }
    if (directFlightFilter) {
        // @ts-ignore: Unreachable code error
        paramsForRouter.directFlight = true;
    }
    const tourIdentities = chain(tourProducts)
        .groupBy((tourProduct) => tourProduct.operator.id)
        .map((tourProductsGrouped) => tourProductsGrouped[0].id)
        .value();

    if (tourIdentities.includes(tourProducts[0].id) && tourProducts[0].price)
        Object.assign(paramsForRouter, {
            hotelMinPriceIdentity: tourProducts[0].id,
            hotelMinPrice: tourProducts[0].price,
            hotelMinPriceOilTax: 0,
        })

    return {
        url: (`/hotel/${hotelId}/tourPage?${$.param({ identities: tourIdentities })}#?${$.param(paramsForRouter)}`),
    };
};