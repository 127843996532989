import {
    computed, ref, Ref, toRefs, unref,
} from 'vue';

import prepareOperatorsIdsFromTourProductsService from '@/js/common/Domain/Service/prepareOperatorsIdsFromTourProducts';
import prepareOperatorsIdsFromHotelOfferProducts from '@/js/common/Domain/Service/prepareOperatorsIdsFromHotelOfferProducts';
import prepareOperatorsDataForPartialPaymentPopup from '@/js/common/Domain/Service/prepareOperatorsDataForPartialPaymentPopup';

import { IOperatorDataForPartialPayment } from '@ve/components/partialPayment/partialPaymentPopup/common/types/OperatorDataForPartialPayment';

import { PartialPaymentTourProduct } from '@ve/components/partialPayment/common/types/PartialPaymentTourProduct';
import prepareFirstPaymentDefinitionForAnyProduct
    from '@ve/components/partialPayment/partialPaymentPopup/common/services/prepareFirstPaymentDefinitionForAnyProduct';
import useProductsByType from '@ve/components/partialPayment/common/use/useProductsByType';
import { PartialPaymentProductType } from '@ve/components/partialPayment/common/types/PartialPaymentProductType';
import { IUseDialogWrapperMethods } from '@ve/components/dialogWrapper/common/types';

interface IProps {
    countryName: string;
    products: PartialPaymentTourProduct[];
    showTourOfferProductsInPopup: boolean;
    showHotelOfferProductsInPopup: boolean;
    hotelPageProduct: boolean;
    productType: PartialPaymentProductType;
    isPopupVisible: boolean;
}

interface IReturn {
    partialPaymentPopup: Ref<IUseDialogWrapperMethods | null>;
    tourOperatorsForPartialPayment: Ref<IOperatorDataForPartialPayment[]>;
    hotelOperatorsForPartialPayment: Ref<IOperatorDataForPartialPayment[]>;
    openPopup():void;
    isPopupVisible: Ref<boolean>;
}

const usePartialPaymentPopup = (props: IProps):IReturn => {
    const {
        products,
        showTourOfferProductsInPopup,
        showHotelOfferProductsInPopup,
        hotelPageProduct,
        productType,
        isPopupVisible,
    } = toRefs(props);

    const partialPaymentPopup: Ref<IUseDialogWrapperMethods | null> = ref(null);

    const tourOperatorsForPartialPayment:Ref<IOperatorDataForPartialPayment[]> = ref([]);

    const hotelOperatorsForPartialPayment:Ref<IOperatorDataForPartialPayment[]> = ref([]);

    const { onlyTourOfferProducts, onlyHotelOfferProducts } = useProductsByType({ products, hotelPageProduct, productType });

    const initCurrentFirstPaymentDefinitionForTourProducts = () => {
        const firstPaymentDefinitionsForTours = prepareFirstPaymentDefinitionForAnyProduct(unref(onlyTourOfferProducts));
        if (unref(onlyTourOfferProducts).length > 0) {
            const operatorsIds = prepareOperatorsIdsFromTourProductsService(unref(onlyTourOfferProducts));
            tourOperatorsForPartialPayment.value = prepareOperatorsDataForPartialPaymentPopup(operatorsIds, firstPaymentDefinitionsForTours);
        }
    };

    const initCurrentFirstPaymentDefinitionForHotelOfferProducts = () => {
        const firstPaymentDefinitionsForHotels = prepareFirstPaymentDefinitionForAnyProduct(unref(onlyHotelOfferProducts));

        if (unref(onlyHotelOfferProducts).length > 0) {
            const operatorsIds = prepareOperatorsIdsFromHotelOfferProducts(unref(onlyHotelOfferProducts));
            hotelOperatorsForPartialPayment.value = prepareOperatorsDataForPartialPaymentPopup(operatorsIds, firstPaymentDefinitionsForHotels);
        }
    };

    const initCurrentFirstPaymentDefinitions = () => {
        if (unref(showTourOfferProductsInPopup)) {
            initCurrentFirstPaymentDefinitionForTourProducts();
        }
        if (unref(showHotelOfferProductsInPopup)) {
            initCurrentFirstPaymentDefinitionForHotelOfferProducts();
        }
    };

    const isPopupCanBeOpened = computed(() => {
        const showTours = unref(showTourOfferProductsInPopup);
        const showHotels = unref(showHotelOfferProductsInPopup);
        const hasDataForTours = unref(tourOperatorsForPartialPayment).length > 0;
        const hasDataForHotels = unref(hotelOperatorsForPartialPayment).length > 0;

        if (showTours && showHotels) return hasDataForTours || hasDataForHotels;
        if (showTours) return hasDataForTours;
        if (showHotels) return hasDataForHotels;
        return false;
    });

    const openPopup = () => {
        initCurrentFirstPaymentDefinitions();
        if (unref(isPopupCanBeOpened)) {
            unref(partialPaymentPopup)?.open();
        }
        isPopupVisible.value = false;
    };

    return {
        isPopupVisible,
        partialPaymentPopup,
        tourOperatorsForPartialPayment,
        hotelOperatorsForPartialPayment,
        openPopup,
    };
};

export default usePartialPaymentPopup;
